import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"

const ProjectsPage = () => {
  return (
    <Layout>
      <Head title="Projects" />
      To Do
    </Layout>
  )
}

export default ProjectsPage
